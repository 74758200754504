import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { LEONavLink } from "LEOTheme/components/LEONavLink";
import { leoColorPetrol5 } from "LEOTheme/LEOColors";
import { leoShadowUIRaised, leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";
import { Project } from "./Main";

export interface ProjectCardProps {
  project: Project;

  // disabled state when not in tags
  disabled: boolean;
}

const useStyles = makeStyles(() => ({
  gridItem: {
    transition: ".15s all ease-in-out",
    "& .icon": {
      transition: ".15s all ease-in-out",
    },
    "&>div>a": {
      opacity: 0,
      transition: "opacity .15s ease-in-out",
    },
    "&:hover>div>a": {
      opacity: 1,
    },
  },
  cardHover: {
    transition: ".1s all ease-in-out",
    "&:hover": {
      backgroundColor: leoColorPetrol5,
    },
    "&:hover .icon": {
      opacity: 0.9,
    },
  },
}));

/**
 *
 *
 * @param props ProjectCardProps
 */
export const ProjectCard = (props: ProjectCardProps) => {
  const classes = useStyles();

  /**
   * Render
   */
  return (
    <Grid
      style={{
        opacity: props.disabled ? 0.5 : 1,
      }}
      className={classes.gridItem}
      key={props.project.projectKey}
      item
      xs={12}
      sm={6}
      md={4}
    >
      <LEONavLink
        key={props.project.projectKey}
        to={"/" + props.project.projectKey}
      >
        <Card
          style={{
            boxShadow: props.disabled ? "0 0 0 0" : leoShadowUIRaised,
            position: "relative",
            top: props.disabled ? 1 : 0,
            height: 70,
          }}
          className={classes.cardHover}
          key={props.project.projectKey}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="icon"
                style={{
                  filter: props.disabled ? "grayscale(1)" : undefined,
                  width: 70,
                  height: 70,
                  flexShrink: 0,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("/images/${props.project.projectKey}.png")`,
                }}
              />
              <div
                style={{
                  padding: leoSpacing * 2,
                  overflow: "hidden",
                }}
              >
                <Typography
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 0,
                  }}
                  variant="h2"
                >
                  {props.project.projectTitle}
                </Typography>
                <p style={{ margin: 0 }}>{props.project.business}</p>
                {/**
                 * <div style={{ marginTop: leoSpacing * 0.5 }}>
                  {(props.project.tags || []).map((t) => (
                    <Chip key={t} size="small" label={t} />
                  ))}
                </div>
                  */}
              </div>
            </div>
          </div>
        </Card>
      </LEONavLink>
      {/**
       * 
      <div style={{ textAlign: "right" }}>
        <CardLinkDisplay small href={props.project.primaryLink}>
          {"Open Application"}
        </CardLinkDisplay>
      </div>
        */}
    </Grid>
  );
};
