import { Chip } from "@material-ui/core";
import { leoColorPetrol0, leoColorWhite } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";

export interface ToggleTagProps {
  label: string;

  onToggle: () => void;

  selected: boolean;
}

/**
 *
 *
 * @param props ToggleTagProps
 */
export const ToggleTag = (props: ToggleTagProps) => {
  /**
   * Render
   */
  return (
    <Chip
      onClick={() => props.onToggle()}
      style={{
        fontSize: 13,
        marginRight: leoSpacing,
        paddingTop: leoSpacing * 0.5,
        paddingBottom: leoSpacing * 0.5,
        height: "auto",
        ...(props.selected === true && {
          backgroundColor: leoColorPetrol0,
          color: leoColorWhite,
        }),
        ...(props.selected === false && {
          opacity: 0.55,
        }),
      }}
      size="medium"
      label={props.label}
    />
  );
};
