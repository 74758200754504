import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { LEOCenterContainer } from "LEOTheme/components/LEOCenterContainer";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import { leoColorSand1, leoColorWhite } from "LEOTheme/LEOColors";
import {
  leoLayoutAppBarHeight,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import _ from "lodash";
import React, { useState } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { CardLinkDisplay } from "./CardLinkDisplay";
import { ContactDisplay } from "./ContactDisplay";
import { Content } from "./Main";
import { ProjectCard } from "./ProjectCard";
import { ProjectDialog } from "./ProjectDialog";
import { ToggleTag } from "./ToggleTag";

export interface HomeProps {
  content: Content;
}

export const useStyles = makeStyles((theme) => ({
  p: {
    marginBottom: leoSpacing * 4,
    fontSize: 16,
    maxWidth: 750,
    lineHeight: 1.5,
  },
  headerP: {
    fontSize: 17,
    marginBottom: "1vw",
    maxWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "66%",
    },
    lineHeight: 1.5,
  },
}));

/**
 * Home
 *
 * @param props HomeProps
 */
export const Home = (props: HomeProps) => {
  const classes = useStyles();

  const { params } = useRouteMatch();
  const history = useHistory();
  const projectKey = params["projectKey"];

  /**
   * local state
   */

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  /**
   * Effects and utils
   */

  const project = props.content.projects.find(
    (p) => p.projectKey === projectKey
  );
  const tags = _.compact(
    _.uniq(props.content.projects.flatMap((p) => p.tags))
  ) as string[];

  // jump to element util
  const jumpToElement = (elementId: string) => {
    const el = document.getElementById(elementId);
    window.scrollTo(
      0,
      el.getBoundingClientRect().y - leoLayoutAppBarHeight - leoSpacing * 2
    );
  };

  /**
   * Render
   */
  return (
    <div style={{ flex: 1, display: "block" }}>
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorWhite,
          overflow: "auto",
        }}
      >
        <LEOPaddedContainer>
          <Typography
            style={{
              marginTop: "0vw",
              marginBottom: "1vw",
              fontSize: 35,
              whiteSpace: "normal",
              lineHeight: 1.25,
            }}
            variant="h1"
          >
            {"R&D"}
            <br />
            <strong>{"System, Data & Analytics"}</strong>
          </Typography>
          <p className={classes.headerP}>
            {
              "Welcome to the R&D Systems, Data & Analytics showcase page. Here you will find a collection of the data applications, experiments and initiatives developed by our team. You're in the right place if you're looking to understand how our capabilities could help you work smarter with data or to get inspired on new ways of thinking about data systems."
            }
          </p>
          <p style={{ marginBottom: 0 }}>{"Jump to:"}</p>
          <CardLinkDisplay
            href=""
            onClick={(e) => {
              e.preventDefault();
              jumpToElement("cases");
            }}
            rotate
          >
            {"Cases"}
          </CardLinkDisplay>
          <CardLinkDisplay
            href=""
            onClick={(e) => {
              e.preventDefault();
              jumpToElement("sda");
            }}
            rotate
          >
            {"SDA Areas"}
          </CardLinkDisplay>
          <CardLinkDisplay
            href=""
            onClick={(e) => {
              e.preventDefault();
              jumpToElement("engagement");
            }}
            rotate
          >
            {"Engagement"}
          </CardLinkDisplay>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {/**
       * Cases
       */}
      <LEOCenterContainer>
        <LEOPaddedContainer>
          <Typography style={{ fontSize: 24 }} variant="h2" id="cases">
            {"Cases"}
          </Typography>
          <div
            style={{
              ...leoStyleFlexRowCenter,
              alignItems: "flex-start",
            }}
          >
            <Grid container spacing={4}>
              {(props.content.projects || []).map((p, i) => (
                <ProjectCard
                  disabled={
                    selectedTags.length > 0 &&
                    _.intersection(selectedTags, p.tags).length === 0
                  }
                  key={p.projectKey + i}
                  project={p}
                />
              ))}
            </Grid>
            <Hidden smDown>
              <div style={{ width: 150, marginLeft: leoSpacing * 4 }}>
                <p
                  style={{
                    marginBottom: leoSpacing,
                    marginTop: -leoSpacing * 4,
                  }}
                >
                  {"Pick area(s) of interest"}
                </p>
                {tags.map((t) => (
                  <ToggleTag
                    selected={
                      selectedTags.length !== 0
                        ? selectedTags.includes(t)
                        : undefined
                    }
                    key={t}
                    label={t}
                    onToggle={() => {
                      if (selectedTags.includes(t)) {
                        setSelectedTags(selectedTags.filter((st) => st !== t));
                      } else {
                        setSelectedTags([...selectedTags, t]);
                      }
                    }}
                  />
                ))}
              </div>
            </Hidden>
          </div>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {/**
       * Technology foundation
       */}
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorSand1,
        }}
      >
        <LEOPaddedContainer
          style={{ paddingTop: leoSpacing * 4, paddingBottom: leoSpacing * 4 }}
        >
          <Typography
            style={{ marginTop: leoSpacing * 4, fontSize: 24 }}
            variant="h2"
            id="sda"
          >
            {"About us"}
          </Typography>
          <p className={classes.p}>
            {
              "The SDA team helps boost digitalization and strengthen our approach to systems, data and analytics in Global R&D. Our purpose is to enable value generation from data and support business decisions that drive our competitive edge. We do this through strategic partnerships embedded in the R&D drug development value chain. Key activities include:"
            }
            <br />
            <NavLink to="/dsaie">{"Read more"}</NavLink>
          </p>
          <p className={classes.p}>
            <ul>
              <li>
                {"Deliver operations support and evolutions of R&D systems"}
              </li>
              <li>
                {
                  "Enable Global R&Ds data strategy and ability to analyze, visualize and utilize data"
                }
              </li>
              <li>
                {
                  "Define and drive advanced analytics, automation, and technology solutions"
                }
              </li>
            </ul>
          </p>
          <p className={classes.p}>
            {"To learn more about our work, people and news visit our "}
            <a
              target="_blank"
              href="https://leopharma.sharepoint.com/sites/RDSystemsDataAnalytics?OR=Teams-HL&CT=1671516865674&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMjExMzAwNDEwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
              rel="noreferrer"
            >
              {"SharePoint website"}
            </a>
            {". To find out more about R&D SDA Organization visit "}
            <a
              target="_blank"
              href="https://leopharma.sharepoint.com/sites/leopulse/AboutUs/Organisation/GlobalRD/Pages/RD_Data_Analytics.aspx?OR=Teams-HL&CT=1671516919505&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMjExMzAwNDEwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
              rel="noreferrer"
            >
              {"Global R&D page"}
            </a>
            {"."}
          </p>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {/**
       * Engagement
       */}
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorWhite,
        }}
      >
        <LEOPaddedContainer
          style={{ paddingTop: leoSpacing * 4, paddingBottom: leoSpacing * 8 }}
        >
          <Typography
            style={{ marginTop: leoSpacing * 4, fontSize: 24 }}
            variant="h2"
            id="engagement"
          >
            {"Engagement"}
          </Typography>
          <ContactDisplay contact={props.content.engagement.sda[0]} />
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3">
                {"Data Science & AI Engineering"}
              </Typography>
              {props.content.engagement.dsaie.map((c) => (
                <ContactDisplay key={c.username} contact={c} />
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3">{"Data & Insights"}</Typography>
              {props.content.engagement.dai.map((c) => (
                <ContactDisplay key={c.username} contact={c} />
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3">
                {"Digital Solution Management​"}
              </Typography>
              {props.content.engagement.dsm.map((c) => (
                <ContactDisplay key={c.username} contact={c} />
              ))}
            </Grid>
          </Grid>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {project && (
        <ProjectDialog onClose={() => history.push("/")} project={project} />
      )}
    </div>
  );
};
