import {
  Button,
  Chip,
  Dialog,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import LEOPrimaryButton from "LEOTheme/components/LEOPrimaryButton";

import {
  leoColorOrange,
  leoColorTextBlack,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import {
  leoBorderRadiusMain,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import React from "react";
import { ContactDisplay } from "./ContactDisplay";
import { Project } from "./Main";

export interface ProjectDialogProps {
  project: Project;

  //
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogWindow: {
    maxWidth: "90%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
    position: "absolute",
    overflowY: "inherit",
    display: "flex",
    right: 0,
    color: leoColorWhite,
    backgroundColor: leoColorWhite,
    "& .LEOTabsNav": {
      color: leoColorTextBlack,
    },
  },
}));

/**
 *
 *
 * @param props ProjectDialogProps
 */
export const ProjectDialog = (props: ProjectDialogProps) => {
  const classes = useStyles();

  /**
   * Render
   */
  return (
    <Dialog
      classes={{ paper: classes.dialogWindow }}
      fullScreen
      disableScrollLock
      BackdropProps={{
        style: {
          // opacity: 0.3,
        },
      }}
      open
      onClose={() => props.onClose()}
    >
      <div
        style={{
          overflowY: "auto",
        }}
      >
        <Hidden smDown>
          <Button
            style={{
              position: "absolute",
              top: leoSpacing * 4,
              right: leoSpacing * 4,
            }}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
            startIcon={<Close />}
          >
            {"Close"}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            style={{
              position: "absolute",
              top: leoSpacing * 1,
              right: leoSpacing * 1,
            }}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          >
            <Close />
          </IconButton>
        </Hidden>
        <LEOPaddedContainer
          style={{
            color: leoColorTextBlack,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <div
                style={{
                  ...leoStyleFlexRowCenter,
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    ...leoStyleFlexRowCenter,
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      borderRadius: leoBorderRadiusMain,
                      width: 55,
                      height: 55,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url("/images/${props.project.projectKey}.png")`,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: leoSpacing * 2,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        marginBottom: leoSpacing * 0.5,
                        marginTop: 0,
                        marginRight: leoSpacing * 3,
                      }}
                      variant="h2"
                    >
                      {props.project.projectTitle}
                    </Typography>
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      {props.project.business}
                    </p>
                  </div>
                </div>
                <div>
                  {props.project.primaryLinks.length && (
                    <Hidden smDown>
                      {props.project.primaryLinks.map((pl) => (
                        <Link key={pl.url} href={pl.url} target="_blank">
                          <LEOPrimaryButton leoColor="petrol">
                            {pl.label}
                          </LEOPrimaryButton>
                        </Link>
                      ))}
                    </Hidden>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={8}>
              <div
                style={{
                  ...leoStyleFlexRowCenter,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: leoSpacing * 4,
                }}
              >
                <Typography variant="h2">{"About"}</Typography>
                {props.project.primaryLinks.length && (
                  <Hidden mdUp>
                    {props.project.primaryLinks.map((pl) => (
                      <Link href={pl.url} target="_blank">
                        <LEOPrimaryButton size="small" leoColor="petrol">
                          {pl.label}
                        </LEOPrimaryButton>
                      </Link>
                    ))}
                  </Hidden>
                )}
              </div>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 900,
                      marginBottom: -leoSpacing,
                      marginTop: 0,
                    }}
                  >
                    {"What benefits it delivers"}
                  </Typography>
                  <p>{props.project.body1}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 900,
                      marginBottom: -leoSpacing,
                      marginTop: 0,
                    }}
                  >
                    {"You might also want to know"}
                  </Typography>
                  <p>{props.project.body2}</p>
                </Grid>
                <Grid item xs={12}>
                  {(props.project.tags || []).map((t) => (
                    <Chip key={t} label={t} />
                  ))}
                </Grid>
              </Grid>
              {!props.project.accessDescription && props.project.accessContact && (
                <>
                  <Typography
                    style={{ marginTop: leoSpacing * 4 }}
                    variant="h2"
                  >
                    {"Access contact"}
                  </Typography>
                  <p>
                    <Link
                      href={
                        "mailto:" +
                        props.project.accessContact.split(", ")[1] +
                        "@leo-pharma.com"
                      }
                    >
                      {props.project.accessContact.split(", ")[0] +
                        ` (${props.project.accessContact
                          .split(", ")[1]
                          ?.toUpperCase()})`}
                    </Link>
                  </p>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography style={{ marginTop: leoSpacing * 4 }} variant={"h3"}>
                {"Contacts"}
              </Typography>
              {(props.project.contacts || []).map((c) => (
                <ContactDisplay contact={c} />
              ))}
              {props.project.mediaLinks.length > 0 && (
                <>
                  <Typography
                    style={{ marginTop: leoSpacing * 4 }}
                    variant={"h3"}
                  >
                    {"Media"}
                  </Typography>
                  {props.project.mediaLinks.map((ml) => (
                    <a href={ml.url} target="_blank" rel="noreferrer">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 160,
                          height: 90,
                          backgroundSize: "cover",
                          backgroundImage: `url(${ml.thumbnail})`,
                        }}
                      >
                        <PlayCircleFilledIcon
                          style={{
                            color: leoColorOrange,
                            fontSize: 50,
                          }}
                        />
                      </div>
                    </a>
                  ))}
                </>
              )}
              {props.project.secondaryLinks.length > 0 && (
                <Typography
                  style={{ marginTop: leoSpacing * 4 }}
                  variant={"h3"}
                >
                  {"Links"}
                  {(props.project.secondaryLinks || []).map((l) => (
                    <>
                      <br />
                      <Link
                        target="_blank"
                        style={{
                          marginTop: leoSpacing,
                          marginBottom: leoSpacing * 2,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                        key={l.url}
                        href={l.url}
                      >
                        {l.label}
                      </Link>
                    </>
                  ))}
                </Typography>
              )}
            </Grid>
          </Grid>
        </LEOPaddedContainer>
      </div>
    </Dialog>
  );
};
