import y from "js-yaml";
import { LEOAppFrame } from "LEOTheme/components/LEOAppFrame";
import { LEOCenteredSpinner } from "LEOTheme/components/LEOCenteredSpinner";
import { DisplayUser } from "LEOTheme/components/LEOUserDisplay";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { DSAIEContent } from "./DSAIEContent";
import { Home } from "./Home";

export interface MainProps {
  appMetadata: AppMetadata;
}

export interface Contact extends DisplayUser {
  role: string;
}

export interface Link {
  label: string;
  url: string;
}

export interface MediaLink {
  thumbnail: string;
  url: string;
}

export interface Project {
  projectTitle: string;
  projectKey: string;
  business: string;
  body1: string;
  body2: string;
  primaryLinks: Link[];
  mediaLinks: MediaLink[];
  secondaryLinks: Link[];
  tags: string[];
  category: string;
  contacts: Contact[];
  access: string;
  accessDescription: string;
  accessContact: string;
  video: string;
}

export interface Content {
  globals: any;
  projects: Project[];
  engagement: {
    sda: Contact[];
    dsaie: Contact[];
    dai: Contact[];
    dsm: Contact[];
  };
}

/**
 *
 *
 * @param props MainProps
 */
export const Main = (props: MainProps) => {
  /**
   * Local state
   */

  const [content, setContent] = useState<Content | null>(null);

  /**
   * Effects and utils
   */

  useEffect(() => {
    (async () => {
      const contentResponse = await fetch("/content.yml");
      const content = y.load(await contentResponse.text()) as Content;
      setContent(content);
    })();
  }, []);

  /**
   * Render
   */

  if (!content) return <LEOCenteredSpinner />;
  return (
    <LEOAppFrame
      LEOAppBarProps={{
        appMetadata: props.appMetadata,
        style: {
          // borderBottom: 0,
        },
      }}
      appMetadata={props.appMetadata}
    >
      <Switch>
        <Route exact path={["/dsaie"]}>
          <DSAIEContent content={content} />
        </Route>
        <Route exact path={["/:projectKey", "/"]}>
          <Home content={content} />
        </Route>
      </Switch>
    </LEOAppFrame>
  );
};
