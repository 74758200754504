import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { LEOCenterContainer } from "LEOTheme/components/LEOCenterContainer";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import { leoColorSand1, leoColorWhite } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";
import { NavLink } from "react-router-dom";
import { ContactDisplay } from "./ContactDisplay";
import { useStyles } from "./Home";
import { Content } from "./Main";
import ScrollToTop from "./ScrollToTop";

export interface DSAIEContentProps {
  content: Content;
}

/**
 *
 *
 * @param props DSAIEContentProps
 */
export const DSAIEContent = (props: DSAIEContentProps) => {
  const classes = useStyles();

  /**
   * Local state
   */

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  /**
   * Effects and utils
   */

  // number of columns
  const noCols = (() => {
    if (mdUp) {
      return 2;
    }
    if (smUp) {
      return 2;
    }
    return 1;
  })();

  /**
   * Render
   */
  return (
    <div style={{ flex: 1, display: "block" }}>
      <ScrollToTop />
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorWhite,
          overflow: "auto",
        }}
      >
        <LEOPaddedContainer>
          <NavLink style={{ fontSize: 18, fontWeight: 600 }} to="/">
            {"Back to SDA overview"}
          </NavLink>
          <Typography
            style={{
              marginTop: "1vw",
              marginBottom: "1vw",
              fontSize: 30,
              whiteSpace: "normal",
              lineHeight: 1.25,
            }}
            variant="h2"
          >
            {"R&D Data Science & "}
            {"AI Engineering"}
          </Typography>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {/**
       * Technology foundation
       */}
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorSand1,
        }}
      >
        <LEOPaddedContainer
          style={{ paddingTop: leoSpacing * 4, paddingBottom: leoSpacing * 4 }}
        >
          <Typography
            style={{ marginTop: leoSpacing * 4, fontSize: 24 }}
            variant="h2"
            id="sda"
          >
            {"The application boilerplate"}
          </Typography>
          <div
            style={{
              fontSize: 16,
              maxWidth: 750,
            }}
          >
            <p>
              {
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies dui tincidunt, varius libero id, cursus leo. Ut tempor finibus libero id interdum. Curabitur sed orci quis ipsum pellentesque tempor. Nullam eget libero eget turpis efficitur pulvinar et vitae enim. Cras tristique eros ultrices massa feugiat pellentesque. Etiam aliquet condimentum lectus, vel ornare purus posuere ut. Nulla rutrum justo at orci semper, id efficitur mi sagittis. "
              }
            </p>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              overflowX: "auto",
            }}
          >
            <img
              style={{
                maxWidth: "100%",
                marginTop: leoSpacing * 6,
                marginBottom: leoSpacing * 4,
              }}
              alt="Technology Framework Illustration"
              src={`/images/tech-overview.svg`}
            />
          </div>
        </LEOPaddedContainer>
      </LEOCenterContainer>
      {/**
       * Compliance & Security
       */}
      <LEOCenterContainer
        containerStyle={{
          backgroundColor: leoColorWhite,
        }}
      >
        <LEOPaddedContainer
          style={{ paddingTop: leoSpacing * 4, paddingBottom: leoSpacing * 4 }}
        >
          <Typography
            style={{ marginTop: leoSpacing * 4, fontSize: 24 }}
            variant="h2"
            id="comp"
          >
            {"Compliance & Security"}
          </Typography>
          <p className={classes.p}>
            {
              "All applications built in DS&AI adhere to Global IT’s guidelines regarding compliance. In many ways, we go above and beyond what’s strictly necessary from a security perspective. There are two cornerstones in our approach to this: "
            }
            <ul>
              <li>{"Our technological framework"}</li>
              <li>
                {
                  "A well-defined, documented, and structured compliance framework"
                }
              </li>
            </ul>
          </p>
          <div style={{ columnCount: noCols, columnGap: leoSpacing * 4 }}>
            <Typography variant="h4">
              {"The Technological Framework"}
            </Typography>
            <p>
              {
                "We aim to manage as many technical components as possible using code. This helps us ensure that components are version controlled, reviewed, and that there is full traceability of their states. We strive to use industry-recognized tools such as Django for API development, React for web development, GraphQL for query language, Azure Databricks for crunching numbers, and Datadog for monitoring."
              }
            </p>
            <p>
              {
                "All our solutions are built on infrastructure managed through the cloud computing service Microsoft Azure, and all supported services are configured using the Infrastructure-as-Code (IaC) framework “Terraform”. Furthermore, we utilize third-party tools to scan and secure our configurations to ensure we minimize the amount of misconfiguration which is often the cause of security breaches in cloud systems."
              }
            </p>
            <p style={{ breakInside: "avoid" }}>
              {
                "To deliver our solutions in an effective, secure, and continuous manner we have adopted the DevOps mindset which allows us to build, test and deploy our code in an automated way. Our platform of choice is Azure DevOps which allows us to utilize Azure Pipeline in which we build, test, and deploy our source code to the cloud environment using pipelines. These pipelines are also used to validate and scan our code for potential security vulnerabilities."
              }
            </p>
            <Typography variant="h4">{"The Compliance Framework"}</Typography>
            <p>
              {
                "Just as with our code, we treat our documentation (SOPs, WIs, application documentation, and application specific compliance documents) with an automation and quality mindset. This means that all documentation is version controlled, peer-reviewed, and access controlled. For all GxP-related documentation we use an Azure DevOps process as established in collaboration with LEO ITQA, making us paperless to the highest extent possible. "
              }
            </p>
            <p>
              {
                "We have in-team CISO approved competencies handling security matters. Our established workflows are based off the relevant LEO SOPs, but with the context of our specific purposes considered. This means that all projects, whether GxP related or not, have a clear and pre-defined documentation path including purpose-customized LEO templates for BIA, DPIA, ITSRA, etc."
              }
            </p>
          </div>
        </LEOPaddedContainer>
        {/**
         * Engagement
         */}
        <LEOCenterContainer>
          <LEOPaddedContainer
            style={{
              paddingTop: leoSpacing * 4,
              paddingBottom: leoSpacing * 8,
            }}
          >
            <Typography
              style={{ marginTop: leoSpacing * 4, fontSize: 24 }}
              variant="h2"
              id="engagement"
            >
              {"Engagement"}
            </Typography>
            <Typography variant="h3">
              {"Data Science & AI Engineering"}
            </Typography>
            {props.content.engagement.dsaie.map((c) => (
              <ContactDisplay key={c.username} contact={c} />
            ))}
          </LEOPaddedContainer>
        </LEOCenterContainer>
      </LEOCenterContainer>
    </div>
  );
};
