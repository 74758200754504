import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React, { PropsWithChildren } from "react";

export interface LEOCenterContainerProps extends PropsWithChildren<any> {
  containerStyle?: CSSProperties;
  innerStyle?: CSSProperties;
}

/**
 * Centered layout container
 *
 * @param props LEOCenterContainerProps
 */
export const LEOCenterContainer = (props: LEOCenterContainerProps) => {
  /**
   * Render
   */
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: leoSpacing * 4,
        paddingBottom: leoSpacing * 4,
        ...props.containerStyle,
      }}
    >
      <div
        style={{
          margin: "auto",
          textAlign: "left",
          maxWidth: 1200,
          ...props.innerStyle,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
