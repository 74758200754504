import { LEOThemeConfig } from "./LEOTheme/utils/type-utils";
export const leoThemeConfig: LEOThemeConfig = {
  disableProfilePictures: false,
  appMetaData: {
    appTitle: "R&D | System Data Analytics",
    contactName: "Troels Ravn Baerentzen",
    contactEmail: "tybdk@leo-pharma.com",
    byline: "Research & Development | System Data Analytics",
  },
};
