import { CSSProperties } from "@material-ui/core/styles/withStyles";
import LEOSpinner, { LEOSpinnerProps } from "LEOTheme/components/LEOSpinner";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import React from "react";

interface LEOCenteredSpinnerProps {
  /** props for the spinner component */
  LEOSpinnerProps?: LEOSpinnerProps;

  /** style */
  style?: CSSProperties;
}

/**
 * Wrapper for spinner that does centering
 *
 * @param props LEOCenteredSpinnerProps
 */
export const LEOCenteredSpinner = (props: LEOCenteredSpinnerProps) => {
  /**
   * Render
   */
  return (
    <div
      style={{
        flex: 1,
        padding: leoSpacing * 2,
        ...leoStyleFlexRowCenter,
        justifyContent: "center",
        ...props.style,
      }}
    >
      <LEOSpinner {...props.LEOSpinnerProps} />
    </div>
  );
};
