import { Link, LinkProps, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { leoColorOrange } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";

export interface CardLinkDisplayProps extends LinkProps {
  small?: boolean;
  rotate?: boolean;
}

const useStyles = makeStyles(() => ({
  link: {
    "& .MuiSvgIcon-root": {
      opacity: 0,
      left: -leoSpacing,
      transition: "all .05s ease-in-out",
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
      left: leoSpacing,
    },
  },
}));

/**
 *
 *
 * @param props CardLinkProps
 */
export const CardLinkDisplay = (props: CardLinkDisplayProps) => {
  const classes = useStyles();

  /**
   * Render
   */
  return (
    <Link
      className={classes.link}
      style={{
        display: "inline-block",
        marginBottom: props.small ? -leoSpacing * 0.25 : leoSpacing * 0.25,
        fontWeight: 600,
        fontSize: props.small ? 14 : 16,
      }}
      target={"_blank"}
      {...props}
    >
      {props.children}
      <span
        style={{
          ...(props.rotate && {
            display: "inline-block",
            transform: "rotate(90deg)",
            marginLeft: leoSpacing * 0.75,
            marginRight: leoSpacing * 1.5,
            position: "relative",
            top: -leoSpacing * 0.45,
          }),
        }}
      >
        <ArrowForwardIcon
          style={{
            fontSize: props.small ? 16 : undefined,
            top: 0.75 * leoSpacing,
            position: "relative",
            color: leoColorOrange,
          }}
        />
      </span>
    </Link>
  );
};
