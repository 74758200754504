import { leoThemeConfig } from "leo-theme-config";
import { LEOAuthProvider } from "LEOTheme/components/LEOAuthProvider";
import { LEOThemeProvider } from "LEOTheme/components/LEOThemeProvider";
import { LEOToastProvider } from "LEOTheme/components/LEOToastProvider";
import { getContextStateUtils } from "LEOTheme/utils/context-state-util";
import { LEOGlobalStateProvider } from "LEOTheme/utils/leo-global-state";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "./Main";

/**
 * App meta used in loading and about screen
 */

// global state
export const [EmbeddingProgressProvider, useEmbeddingProgress] =
  getContextStateUtils<{
    embeddingProgress: number;
    success: boolean;
  }>({ embeddingProgress: 100, success: true }, "embedding-progress");

/**
 * Root App and main providers
 */
export const App = () => {
  return (
    <Router>
      <LEOThemeProvider>
        <LEOToastProvider>
          <LEOGlobalStateProvider>
            <LEOAuthProvider
              feOnlyAuth={true}
              appMetadata={leoThemeConfig.appMetaData}
            >
              <Main appMetadata={leoThemeConfig.appMetaData} />
            </LEOAuthProvider>
          </LEOGlobalStateProvider>
        </LEOToastProvider>
      </LEOThemeProvider>
    </Router>
  );
};
