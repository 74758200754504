import { LEOUserDisplay } from "LEOTheme/components/LEOUserDisplay";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";
import { Contact } from "./Main";

export interface ContactDisplayProps {
  /** contact  */
  contact: Contact;
}

/**
 *
 *
 * @param props ContactDisplayProps
 */
export const ContactDisplay = (props: ContactDisplayProps) => {
  /**
   * Render
   */
  return (
    <LEOUserDisplay
      emailLinkOnName
      subLine={<small>{props.contact.role}</small>}
      labelStyle={{
        whiteSpace: "normal",
      }}
      style={{
        fontSize: 16,
        alignItems: "flex-start",
        marginTop: leoSpacing * 2,
        marginBottom: leoSpacing * 2,
      }}
      user={props.contact}
    />
  );
};
